<template>
  <div class="equipment-model" ref="equipmentModel">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddEquipeent"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="终端名称：">
          <el-input
            v-model.trim="form.deviceName"
            clearable
            placeholder="请输入终端名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="厂家名称：">
          <el-select
            v-model="form.makerId"
            clearable
            placeholder="请选择终端厂家"
          >
            <el-option
              v-for="item in producerList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终端型号：">
          <el-select
            v-model="form.model"
            clearable
            placeholder="请选择终端型号"
          >
            <el-option
              v-for="item in modelList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="deviceName" label="终端名称"></el-table-column>
      <el-table-column prop="producerName" label="厂家名称">
        <template slot-scope="scope">
          {{ producerObj[Number(scope.row.makerId)] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="model"
        label="终端型号"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ modelObj[scope.row.model] }}
        </template></el-table-column
      >
      <el-table-column prop="type" label="终端类型">
        <template slot-scope="scope">
          {{ typeObj[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateEquipeent(scope.row)"
            type="text"
            size="small"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            sort="primary"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增终端类型 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <add-equip
        ref="addEquip"
        :item="equipItem"
        @close="onDialogClose"
        @onLoading="onLoading"
      ></add-equip>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict } from '@/common/utils/index'
import { queryDeviceModelList, queryDictsByCodes } from '@/api/lib/api.js'
import addEquip from './components/addEquip.vue'

export default {
  name: 'equipmentModel',
  components: { addEquip },
  data() {
    return {
      tableHeight: 0,
      total: 0,
      tableData: [],
      form: {
        makerId: '',
        deviceName: '',
        model: '',
        type: '',
        currentPage: 1,
        pageSize: 10
      },
      producerList: [],
      modelList: [],
      producerObj: null,
      modelObj: null,
      typeObj: null,
      equipItem: null,
      title: '',
      dialogVisible: false,
      editLoading: false,
      searchLoading: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.equipmentModel.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 点击查询
    onSearch() {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    //获取数据
    getListByField() {
      queryDeviceModelList(this.form)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData = res.data.list
            this.total = res.data.total
          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page
      this.getListByField()
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size
      this.getListByField()
    },
    // 新增
    onAddEquipeent() {
      this.title = '新增终端型号'
      this.equipItem = null
      this.dialogVisible = true
    },
    // 编辑
    onUpdateEquipeent(row) {
      this.title = '编辑终端型号'
      this.equipItem = row
      this.dialogVisible = true
    },
    //  保存
    onSave() {
      this.$refs.addEquip.onSave()
    },
    onLoading(flag) {
      this.editLoading = flag
    },
    // 弹窗关闭时的回调
    onDialogClose(flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: 'SBCJ,SBXH,ZDLX' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
          this.modelList = res.data.SBXH
          this.producerObj = formatDict(this.producerList)
          this.modelObj = formatDict(this.modelList)
          this.typeObj = formatDict(res.data.ZDLX)
        }
      })
    }
  },
  created() {
    this.getDicts()
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.equipment-model {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.theme-project-resourse {
  .equipment-model {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
